import React from "react";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";

import urljoin from "url-join";
import PropTypes from "prop-types";

// Local
import config from "data/SiteConfig";

// Aah yes SEO is just a component
const SEO = ({
	postNode,
	postPath,
	postSEO,
	pageTitle,
	pageDescription,
	pageImage,
	useEmailScript,
	noindex = false
}) => {
	let title;
	let description;
	let image;
	let postURL;

	console.log(noindex);

	const location = useLocation();

	if (postSEO) {
		const postMeta = postNode.frontmatter;
		({ title } = postMeta);
		description = postMeta.description
			? postMeta.description
			: postNode.excerpt;
		image = postMeta.social_image;

		postURL = urljoin(config.siteUrl, config.pathPrefix, postPath);

		// Remove duplicate slashes
		postURL = postURL.replace(".com//", ".com/");
	} else {
		title = pageTitle || config.siteTitle;
		description = pageDescription || config.siteDescription;
		image = pageImage || config.siteLogo;

		postURL = urljoin(config.siteUrl, location.pathname);
		postURL = postURL.replace(".com//", ".com/");
	}

	let blogURL = urljoin(config.siteUrl, config.pathPrefix);
	blogURL = blogURL.replace(".com//", ".com/");

	const schemaOrgJSONLD = [
		{
			"@context": "https://schema.org",
			"@graph": [
				{
					"@type": "WebSite",
					"@id": "https://www.pipedout.com/#website",
					url: "https://www.pipedout.com/",
					name: "Piped Out",
					description: "SEO for everyone",
					publisher: {
						"@id": "https://www.pipedout.com/#organization"
					},
					inLanguage: "en-GB",
					copyrightHolder: {
						"@id": "https://www.pipedout.com/#organization"
					}
				},
				{
					"@type": ["Organization", "Brand"],
					"@id": "https://www.pipedout.com/#organization",
					name: "Piped Out",
					url: "https://www.pipedout.com/",
					logo: {
						"@type": "ImageObject",
						inLanguage: "en-US",
						"@id": "https://www.pipedout.com/#/schema/logo/image/",
						url:
							"https://www.pipedout.com/images/logos/logo%20large%20colour.png",
						contentUrl:
							"https://www.pipedout.com/images/logos/logo%20large%20colour.png",
						width: 391,
						height: 94,
						caption: "Piped Out"
					},
					image: {
						"@id": "https://www.pipedout.com/#/schema/logo/image/"
					},
					sameAs: [
						"https://www.linkedin.com/company/93168931/",
						"https://www.youtube.com/channel/UCAhWgfZvG6nLbanNZd_kbyw",
						"https://www.crunchbase.com/organization/piped-out",
						"https://find-and-update.company-information.service.gov.uk/company/12820465"
					],
					founder: {
						"@type": "Person",
						name: "Dominic Woodman"
					},
					foundingDate: "2020-08-018",
					numberOfEmployees: 4,
					description:
						"Piped Out is a managed data service for your SEO team. It's like hiring a data engineer and data analyst, both with deep domain expertise & their own tool stack. We'll get rid of your data problems and make you better at your job.",
					legalName: "PipedOut LTD"
				}
			]
		}
	];
	// if (postSEO) {
	// 	schemaOrgJSONLD.push(
	// 		{
	// 			"@context": "http://schema.org",
	// 			"@type": "BreadcrumbList",
	// 			itemListElement: [
	// 				{
	// 					"@type": "ListItem",
	// 					position: 1,
	// 					item: {
	// 						"@id": postURL,
	// 						name: title,
	// 						image
	// 					}
	// 				}
	// 			]
	// 		},
	// 		{
	// 			"@context": "http://schema.org",
	// 			"@type": "BlogPosting",
	// 			url: blogURL,
	// 			name: title,
	// 			alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
	// 			headline: title,
	// 			image: {
	// 				"@type": "ImageObject",
	// 				url: image
	// 			},
	// 			description
	// 		}
	// 	);
	// }
	let facebookImg;
	let twitterImg;
	let twitterImgCard;
	if (image) {
		let imagePath = urljoin(config.siteUrl, config.pathPrefix, image);
		imagePath = imagePath.replace(".com//", ".com/");

		// NOTE: React Fragement in Helmet causes hard to spot errors
		facebookImg = <meta property="og:image" content={imagePath} />;
		twitterImg = <meta name="twitter:image" content={imagePath} />;
		twitterImgCard = (
			<meta name="twitter:card" content="summary_large_image" />
		);
	}

	let emailScript;
	if (useEmailScript) {
		emailScript = (
			<script
				type="text/javascript"
				id="hs-script-loader"
				async
				defer
				src="https://pipedout.activehosted.com/f/embed.php?id=1"
			/>
		);
	}
	return (
		<Helmet>
			{/* Title & meta */}
			<title>{`${title} | ${config.siteTitleShort}`}</title>

			{/* Add noindex if noindex prop is true */}
			{noindex && <meta name="robots" content="noindex" />}

			{/* General tags */}
			<meta name="description" content={description} />
			<meta name="author" content="Dominic Woodman" />
			<link rel="canonical" href={postURL} />

			{/* Schema.org tags */}
			<script type="application/ld+json">
				{JSON.stringify(schemaOrgJSONLD)}
			</script>

			{/* OpenGraph tags */}
			<meta property="og:url" content={postURL || blogURL} />
			{postSEO ? <meta property="og:type" content="article" /> : null}
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{facebookImg}

			{/* Twitter Card tags */}

			<meta
				name="twitter:creator"
				content={config.userTwitter ? config.userTwitter : ""}
			/>
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{twitterImg}
			{twitterImgCard}

			{/* Favicon */}
			{/* We only provide one size because at this point we think people do a good
			job of scaling and the size is neglible. */}
			<link
				rel="shortcut icon"
				type="image/png"
				sizes="192x192"
				href="/images/logos/favicon-192.png"
			/>
			{emailScript}

			{/* Live Chat */}
			{/* We're testing out live chat */}
			{/* <script
				type="text/javascript"
				id="hs-script-loader"
				async
				defer
				src="//js-na1.hs-scripts.com/20067241.js"
			></script> */}
		</Helmet>
	);
};

SEO.propTypes = {
	postNode: PropTypes.shape(),
	postPath: PropTypes.string,
	postSEO: PropTypes.shape(),
	pageTitle: PropTypes.string,
	pageDescription: PropTypes.string,
	pageImage: PropTypes.shape(),
	useEmailScript: PropTypes.bool
};

export default SEO;

// {
//   "@context": "https://schema.org",
//   "@graph": [
//     {
//       "@type": "WebSite",
//       "@id": "https://www.pipedout.com/#website",
//       "url": "https://www.pipedout.com/",
//       "name": "Piped Out",
//       "description": "Piped Out is a managed data service for your SEO team. We provide analytics and data warehousing services to provide the best possible data and analysis for SEO teams.",
//       "publisher": {
//         "@id": "https://www.pipedout.com/#organization"
//       },
//       "inLanguage": "en-GB"
//     },
//     {
//       "@type": [
//         "Organization",
//         "Brand"
//       ],
//       "@id": "https://www.pipedout.com/#organization",
//       "name": "Piped Out",
//       "url": "https://www.pipedout.com/",
//       "sameAs": [
//         "https://www.linkedin.com/company/piped-out/",
//         "https://twitter.com/yoast",
//         "https://find-and-update.company-information.service.gov.uk/company/12820465",
//         "https://www.youtube.com/@pipedout"
//       ],
//       "founder": {
//         "@type": "Person",
//         "name": "Dominic Woodman",
//         "url": "https://www.domwoodman.com/",
//         "sameAs": "https://www.domwoodman.com/"
//       },
//       "foundingDate": "2020-09-01",
//       "numberOfEmployees": 4,
//       "description": "Piped Out is a managed data service for your SEO team. We provide analytics and data warehousing services to provide the best possible data and analysis for SEO teams.",
//       "legalName": "Piped Out LTD"
//     }
//   ]
// }
